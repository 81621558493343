import React, { useLayoutEffect, useRef } from "react";
import "../styles/ForthSection.css";
import illustration from "../assets/forthsection_partners_illustration2.png";
import mode from "../assets/mode.png";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

function ForthSection() {
  const forthSectionRef = useRef();
  // gsap animation code start - DO NOT REMOVE THIS CODE
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    let ctx = gsap.context(() => {
      // use scoped selectors
      gsap.set(".forth-title-gsap", { x: 0, opacity: 1 });
      // gsap.from(".second-title-gsap", { opacity: 0, x: -50, duration: 1 });
      gsap.from(".forth-title-gsap", {
        opacity: 0,
        y: 110,
        duration: 1,
        scrollTrigger: {
          trigger: ".forth-main",
          start: "top 80%", // Change start position to trigger the animation

          toggleActions: "play none none reverse",
        },
      });

      // gsap.from(".second-title-gsap", { opacity: 0, x: -50, duration: 1 });
      gsap.from(".forth-image-gsap", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".forth-main",
          start: "top 70%", // Change start position to trigger the animation
          toggleActions: "play none none reverse",
        },
      });
      // gsap.utils.toArray(".sc-right-item").forEach((element) => {

      gsap.from(".forth-icon", {
        opacity: 0,
        x: 100,
        duration: 2,
        scrollTrigger: {
          trigger: ".forth-icon",
          start: "top 75%", // Change start position to trigger the animation
          toggleActions: "play none none reverse",
        },
      });
      gsap.from(".partner-text", {
        opacity: 0,
        duration: 2,
        scrollTrigger: {
          trigger: ".forth-icon",
          start: "top 70%", // Change start position to trigger the animation

          toggleActions: "play none none reverse",
        },
      });

      // });
    }, forthSectionRef);
    // clean up function
    return () => ctx.revert();
  }, []);

  return (
    <div className="forths-container" ref={forthSectionRef}>
      <div className="forth-main">
        <div className="forth-left">
          <h2 className="forth-title-gsap">
            THE MODE DOMAINS <br />
            PARTNER ECOSYSTEM
          </h2>
          <img
            src={illustration}
            alt="mode partner"
            className="forth-image-gsap"
          />
        </div>
        <div className="forth-right">
          <div className="forth-right-item">
            <div className="forth-icon">
              {/* spaceid svg icon */}
              <svg
                width="223"
                height="34"
                viewBox="0 0 223 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.1343 23.0154C20.1343 21.571 21.3204 20.4 22.7835 20.4H23.8432C25.3063 20.4 26.4924 21.571 26.4924 23.0154V24.0616C26.4924 25.506 25.3063 26.6769 23.8432 26.6769H22.7835C21.3204 26.6769 20.1343 25.506 20.1343 24.0616V23.0154Z"
                  fill="white"
                />
                <path
                  d="M22.7835 10.9846C21.3204 10.9846 20.1343 12.1556 20.1343 13.6V14.6462C20.1343 16.0906 21.3204 17.2615 22.7835 17.2615H23.8432C25.3063 17.2615 26.4924 16.0906 26.4924 14.6462V13.6C26.4924 12.1556 25.3063 10.9846 23.8432 10.9846H22.7835Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10.597 0C4.74442 0 0 4.68379 0 10.4615V23.5385C0 29.3162 4.74442 34 10.597 34H23.8432C29.6958 34 34.4402 29.3162 34.4402 23.5385V10.4615C34.4402 4.68379 29.6958 0 23.8432 0H10.597ZM23.8432 4.18462H10.597C7.08544 4.18462 4.23879 6.99489 4.23879 10.4615V23.5385C4.23879 27.0051 7.08544 29.8154 10.597 29.8154H23.8432C27.3547 29.8154 30.2013 27.0051 30.2013 23.5385V10.4615C30.2013 6.99489 27.3547 4.18462 23.8432 4.18462Z"
                  fill="white"
                />
                <path
                  d="M67.9756 25.5471C68.6973 24.4047 69.0675 23.0805 69.041 21.735C69.041 20.4907 68.762 19.4163 68.2104 18.5377C67.6134 17.6121 66.8028 16.8386 65.8448 16.2804C64.5964 15.5592 63.2761 14.9661 61.9049 14.5103C61.8031 14.4779 61.7128 14.4456 61.6356 14.4165L61.4337 14.3452C60.5341 14.0427 59.861 13.7984 59.4277 13.6188C59.0313 13.4661 58.6648 13.2471 58.3442 12.9716C58.2038 12.8506 58.0916 12.7013 58.0151 12.5338C57.9386 12.3663 57.8996 12.1845 57.9009 12.0007C57.8924 11.7276 57.9507 11.4565 58.0711 11.2104C58.1913 10.9642 58.37 10.7502 58.5921 10.5865C59.0304 10.2532 59.6591 10.0833 60.4585 10.0833C61.4446 10.092 62.4182 10.3014 63.3183 10.6982C64.1005 11.0285 64.829 11.4706 65.4803 12.0104L68.624 8.23387C67.5335 7.2754 66.287 6.50469 64.937 5.95401C63.4288 5.36745 61.8187 5.07777 60.1976 5.1013C58.3918 5.1013 56.8732 5.41521 55.6912 6.03493C54.5093 6.65464 53.6441 7.47176 53.0991 8.47334C52.5457 9.49438 52.2606 10.6359 52.2701 11.7936C52.2701 13.8291 52.9267 15.3906 54.2105 16.4374C55.6331 17.5622 57.2714 18.3927 59.027 18.8791C59.9298 19.127 60.8131 19.439 61.6701 19.8127C62.307 20.1121 62.7323 20.4114 62.9686 20.7253C63.2121 21.0535 63.3395 21.4514 63.3314 21.858C63.3382 22.1597 63.2664 22.4582 63.1227 22.7249C62.979 22.9914 62.7682 23.2172 62.5107 23.3806C61.9869 23.7366 61.2351 23.9096 60.2123 23.9096C58.1718 23.9096 56.1969 23.0472 54.3419 21.345L50.7992 24.9743C52.0123 26.2256 53.4623 27.2305 55.0674 27.9322C56.7407 28.6482 58.5474 29.012 60.3716 29.0001C62.1051 29.0001 63.6548 28.6959 64.9567 28.094C66.1904 27.5486 67.2385 26.6644 67.9756 25.5471Z"
                  fill="white"
                />
                <path
                  d="M143.773 23.1187L141.18 21.0703C140.336 22.1699 139.165 22.983 137.83 23.3966C136.496 23.8101 135.064 23.8034 133.733 23.3777C132.403 22.9518 131.239 22.1279 130.406 21.0205C129.572 19.9131 129.11 18.5772 129.083 17.1986C129.055 15.82 129.464 14.4672 130.252 13.3283C131.04 12.1894 132.17 11.3209 133.482 10.8438C134.794 10.3666 136.225 10.3043 137.575 10.6658C138.926 11.0271 140.128 11.7941 141.015 12.8602L145.236 9.52864C143.647 7.59896 141.489 6.20438 139.06 5.53821C136.631 4.87204 134.051 4.9673 131.68 5.81079C129.308 6.65428 127.262 8.20418 125.824 10.2456C124.386 12.2871 123.628 14.7188 123.656 17.2038C123.683 19.6887 124.494 22.1037 125.977 24.1139C127.46 26.124 129.54 27.6296 131.929 28.4222C134.319 29.2146 136.9 29.2546 139.313 28.5366C141.727 27.8185 143.854 26.378 145.4 24.4148L143.773 23.1187Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M94.4375 12.7615C94.4375 14.344 94.1086 15.7533 93.4525 16.9507C92.8042 18.1364 91.8157 19.1074 90.6103 19.7418C89.3662 20.4052 87.8706 20.7418 86.165 20.7418H82.0904V28.1444H76.4662V5.09998H86.0862C87.7587 5.09998 89.2431 5.43493 90.4993 6.09347C91.7107 6.71458 92.7189 7.66092 93.4077 8.82315C94.1017 10.0216 94.4573 11.3816 94.4375 12.7615ZM87.2619 15.4038C87.7329 15.1568 88.1272 14.7875 88.401 14.3359C88.6791 13.875 88.8229 13.3478 88.8177 12.8117C88.8255 12.3008 88.6782 11.7993 88.3941 11.3716C88.1117 10.9541 87.7199 10.6189 87.2619 10.4007C86.7694 10.1675 86.2287 10.05 85.6824 10.0577H82.0904V15.7857H85.6659C86.2218 15.7917 86.7702 15.6604 87.2619 15.4038Z"
                  fill="white"
                />
                <path
                  d="M158.243 18.7661H167.027V14.2113H158.243V10.0577H169.473V5.09998H152.618V28.1444H169.727V23.1867H158.243V18.7661Z"
                  fill="white"
                />
                <path
                  d="M186.6 5.09998H192.224V28.1444H186.6V5.09998Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M220.311 22.4834C221.379 20.7079 221.942 18.6816 221.94 16.6176C221.939 14.5537 221.374 12.5282 220.303 10.7541C219.216 9.00768 217.676 7.57799 215.842 6.61187C213.928 5.59935 211.786 5.07952 209.614 5.10059H200.872V28.145H209.666C211.829 28.1659 213.963 27.646 215.867 26.6338C217.698 25.6661 219.232 24.233 220.311 22.4834ZM215.409 19.8735C214.877 20.8161 214.089 21.5934 213.132 22.1177C212.134 22.6618 211.01 22.9406 209.869 22.9268H206.497V10.327H209.852C210.996 10.3117 212.124 10.5905 213.124 11.136C214.081 11.6622 214.872 12.439 215.409 13.3802C215.966 14.3694 216.249 15.4854 216.23 16.6164C216.249 17.754 215.966 18.8767 215.409 19.8735Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M105.544 5.09998H110.558L119.525 28.1768H113.754L112.481 24.4925V24.4812H103.567V24.4925L102.294 28.1768H96.5254L105.544 5.09998ZM108.034 11.5641L107.954 11.7987L105.249 19.6269H110.813L108.117 11.7987L108.034 11.5641Z"
                  fill="white"
                />
              </svg>
            </div>
            <span className="partner-text">
              A decentralized universal Web3 name service network for auctions
            </span>
          </div>
          <div className="forth-right-item">
            <div className="forth-icon">
              {/* blockscout svg icon */}
              <svg
                width="224"
                height="42"
                viewBox="0 0 224 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M31.9213 3.17161C31.9213 2.07757 31.0344 1.19067 29.9403 1.19067H25.6056C24.5116 1.19067 23.6247 2.07757 23.6247 3.17161V7.46363C23.6247 8.55768 24.5116 9.44456 25.6056 9.44456H28.3298C29.4239 9.44456 30.3108 10.3315 30.3108 11.4255V38.828C30.3108 39.9221 31.1977 40.809 32.2917 40.809H36.6265C37.7205 40.809 38.6074 39.9221 38.6074 38.828V11.4251C38.6074 10.331 37.7205 9.44412 36.6265 9.44412H33.9023C32.8082 9.44412 31.9213 8.55723 31.9213 7.46319V3.17161ZM15.2719 3.17174C15.2719 2.07769 14.385 1.1908 13.291 1.1908H8.95628C7.86225 1.1908 6.97535 2.07769 6.97535 3.17174V7.46344C6.97535 8.55747 6.08845 9.44437 4.99442 9.44437H1.98093C0.886894 9.44437 0 10.3313 0 11.4253V38.8282C0 39.9222 0.886894 40.8091 1.98093 40.8091H6.31565C7.4097 40.8091 8.29658 39.9222 8.29658 38.8282V11.4256C8.29658 10.3316 9.18348 9.44469 10.2775 9.44469H13.291C14.385 9.44469 15.2719 8.55779 15.2719 7.46376V3.17174ZM23.5101 18.9716C23.5101 17.8777 22.6232 16.9908 21.5291 16.9908H17.1944C16.1003 16.9908 15.2134 17.8777 15.2134 18.9716V31.0459C15.2134 32.14 16.1003 33.0269 17.1944 33.0269H21.5291C22.6232 33.0269 23.5101 32.14 23.5101 31.0459V18.9716Z"
                  fill="white"
                />
                <path
                  d="M54.8762 10.1699H64.6768C68.8285 10.1699 71.0744 12.3544 71.0744 15.4995C71.1052 16.4824 70.8389 17.4518 70.3105 18.2801C69.7822 19.1085 69.0165 19.7574 68.1138 20.1414V20.2438C69.2812 20.5992 70.3 21.3296 71.0133 22.3225C71.7265 23.3154 72.0947 24.5159 72.0614 25.7392C72.0614 29.2547 69.6305 32.0195 65.2212 32.0195H54.8762V10.1699ZM64.7448 19.0492C66.3102 19.0492 67.4332 17.8887 67.4332 16.1236C67.4332 14.3585 66.3102 13.1979 64.7448 13.1979H58.5271V19.0492H64.7448ZM65.1872 29.0159C67.0589 29.0159 68.4201 27.5823 68.4201 25.5002C68.4201 23.4181 67.0589 22.0187 65.1872 22.0187H58.5271V29.0159H65.1872Z"
                  fill="white"
                />
                <path
                  d="M76.6213 12.7689H74.5697V9.47266H80.2187V32.0049H76.6116L76.6213 12.7689Z"
                  fill="white"
                />
                <path
                  d="M83.5586 23.9302C83.5586 18.981 87.2046 15.3289 92.241 15.3289C97.2775 15.3289 100.953 18.9469 100.953 23.9302C100.953 28.9135 97.3115 32.4973 92.241 32.4973C87.1706 32.4973 83.5586 28.8793 83.5586 23.9302ZM91.2883 29.2548H93.2327C95.4447 29.2548 97.3844 26.8997 97.3844 23.9302C97.3844 20.9606 95.4398 18.5665 93.2327 18.5665H91.2883C89.0763 18.5665 87.1366 21.0046 87.1366 23.9302C87.1366 26.8558 89.0763 29.2548 91.2883 29.2548Z"
                  fill="white"
                />
                <path
                  d="M103.296 23.896C103.296 18.8444 106.772 15.3289 111.774 15.3289C115.79 15.3289 118.954 17.4109 119.465 21.6676H115.96C115.62 19.5174 114.088 18.5616 112.523 18.5616H110.821C108.643 18.5616 106.874 20.951 106.874 23.9253C106.874 26.8997 108.643 29.3182 110.821 29.3182H112.523C113.393 29.3216 114.232 28.9973 114.875 28.4095C115.518 27.8216 115.917 27.013 115.994 26.1439H119.499C119.013 30.3422 115.824 32.5266 111.721 32.5266C106.733 32.4973 103.296 28.9476 103.296 23.896Z"
                  fill="white"
                />
                <path
                  d="M122.727 9.47266H126.3V21.9017H128.891L133.724 15.7921H137.807L131.852 23.3353L138.079 32.0049H133.792L128.585 24.837H126.3V32.0049H122.727V9.47266Z"
                  fill="white"
                />
                <path
                  d="M139.713 27.1386H143.15C143.319 28.5722 144.204 29.4938 146.066 29.4938H148.317C149.916 29.4938 150.665 28.6747 150.665 27.5824C150.665 26.4901 150.053 25.8417 148.59 25.6319L144.808 25.1443C141.609 24.769 140.179 22.8575 140.179 20.3658C140.179 17.0891 142.61 15.3142 146.951 15.3142C151.108 15.3142 153.626 16.9868 153.758 20.7071H150.321C150.15 19.3077 149.572 18.3179 147.802 18.3179H145.755C144.224 18.3179 143.51 19.137 143.51 20.1952C143.51 21.2533 144.19 21.9701 145.653 22.1749L149.47 22.6625C152.566 23.0379 153.996 24.7105 153.996 27.3045C153.996 30.6494 151.784 32.4926 146.884 32.4926C142.143 32.4974 139.844 30.6884 139.713 27.1386Z"
                  fill="white"
                />
                <path
                  d="M156.241 23.896C156.241 18.8444 159.712 15.3289 164.72 15.3289C168.735 15.3289 171.9 17.4109 172.41 21.6676H168.906C168.566 19.5174 167.034 18.5616 165.469 18.5616H163.766C161.584 18.5616 159.814 20.951 159.814 23.9253C159.814 26.8997 161.584 29.3182 163.766 29.3182H165.469C166.338 29.3216 167.178 28.9973 167.821 28.4095C168.463 27.8216 168.862 27.013 168.939 26.1439H172.445C171.959 30.3422 168.77 32.5266 164.667 32.5266C159.684 32.4973 156.241 28.9476 156.241 23.896Z"
                  fill="white"
                />
                <path
                  d="M174.714 23.9302C174.714 18.981 178.356 15.3289 183.393 15.3289C188.429 15.3289 192.109 18.9469 192.109 23.9302C192.109 28.9135 188.468 32.4973 183.393 32.4973C178.317 32.4973 174.714 28.8793 174.714 23.9302ZM182.44 29.2548H184.384C186.596 29.2548 188.541 26.8997 188.541 23.9302C188.541 20.9606 186.596 18.5665 184.384 18.5665H182.44C180.228 18.5665 178.289 21.0046 178.289 23.9302C178.289 26.8558 180.237 29.2548 182.449 29.2548H182.44Z"
                  fill="white"
                />
                <path
                  d="M195.075 26.0122V15.8115H198.647V25.4026C198.647 27.7579 199.839 29.1573 201.404 29.1573H203.349C205.016 29.1573 206.514 27.6214 206.514 25.4026V15.8115H210.091V32.0244H206.616V29.8058C205.643 31.4782 203.927 32.5022 201.307 32.5022C197.389 32.4973 195.075 30.2105 195.075 26.0122Z"
                  fill="white"
                />
                <path
                  d="M216.037 28.2308V18.9663H212.906V15.8116H216.037V11.2671H219.645V15.8116H223.864V18.9518H219.645V27.2118C219.645 28.2699 219.985 28.7819 221.141 28.7819H224V32.0244H220.111C217.496 32.0195 216.037 30.6542 216.037 28.2308Z"
                  fill="white"
                />
              </svg>
            </div>
            <span className="partner-text">
              An EVM-based blockchain analyzer for BlockExplorer
            </span>
          </div>
          <div className="forth-right-item">
            <div className="forth-icon">
              {/* mode svg icon */}
              <img src={mode} alt="mode network" />
            </div>
            <span className="partner-text">
              To bridge transactions across chains
            </span>
          </div>
          <div className="forth-right-item">
            <button onClick={() => window.open("https://app.modedomains.xyz/")}>
              Get your .mode on
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForthSection;
